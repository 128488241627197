<template>
    <div id="box">
        <!-- <Header :headerIndex="0"></Header> -->
        <div class="">
            <div class="" v-html="articledetail[type]"></div>

        </div>
        <!-- <Footer></Footer> -->
    </div>
</template>
<script>
import { numberToCurrency } from "../assets/filters";
export default {
    filters: {
        numberToCurrency
    },
    data() {
        return {
            price: '',
            articledetail:'',
            id:0
        };
    },
    created() {
        this.type=this.$route.query.type
        this.config()
    },

    methods: {
        config(){
            this.$api.config({}).then((res) => {
                if (res.code == 1) {
                    console.log(88)
                    this.articledetail=res.data
                } else {
                    // that.$message.error(res.msg);
                    this.$message({
						message: res.msg,
						type: 'error',
						offset: window.screen.height / 2
					})
                }
            })
        },
        godetail(item){
            this.$router.push({path:'/articledetail',query: {id:item.id}})
        }
    },
};
</script>
